import { computed, ref } from "vue"
import { useStore } from '@/store'
import { AuthActionTypes } from '@/store/auth/actions'
import { useRouter } from 'vue-router'



export default function () {
    const store = useStore()
    const router = useRouter()
    const displayUserSettingPopup = ref(false)
    const currentUser = computed(() => store.state.auth.currentUser)
    displayUserSettingPopup.value = currentUser.value.displayUserSettingPopup
    const goToAccount = () => {
        router.push({ name: 'UserProfile' })
    }
    return { displayUserSettingPopup, goToAccount }
}