
import Button from '@/components/UI/Button.vue'
import Popup from '@/components/Popup.vue'
import Navigation from '@/components/UI/Navigation.vue'
import superAdminNavigation from '@/components/UI/superAdminNavigation.vue'
import { ref, computed, defineComponent, onMounted, onUnmounted } from 'vue'
import { useStore } from '@/store'
import { MutationTypes } from '@/store/options/mutations'
import { useI18n } from 'vue-i18n'
import { useRouter, useRoute } from 'vue-router'
import { VueCookieNext } from 'vue-cookie-next'
export default defineComponent({
  components: {
    Button,
    Popup,
    Navigation,
    superAdminNavigation
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const { locale } = useI18n({ useScope: 'global' })
    const el = ref<HTMLDivElement>(document.createElement('div'))
    const isDropdownOpen = ref(false)
    const loader = computed(() => store.state.auth.loader)
    const user = computed(() => store.state.auth.currentUser)
    const companyLogo = computed(() => `data:${user.value.company?.logoType};base64,${user.value.company?.logo}`)
    const role = computed(() =>
      store.state.auth.currentUser && store.state.auth.currentUser.roles ? store.state.auth.currentUser.roles[0] : ''
    )
    const roles = computed(() => store.state.auth.currentUser?.roles)
    const toggleOpen = computed(() => store.state.options.toggleOpen)
    const showPopUp = computed(() => store.state.options.showPopUp)
    const isLoggedIn = computed(() => store.state.options.isLoggedIn)
    const getSuperAdmin = computed(() => VueCookieNext.getCookie('adminCompanyId'))
    const goToDashboard = () => {
      router.push({ name: 'Dashboard' })
    }
    const onClick = () => {
      store.commit(MutationTypes.SET_POPUP, true)
    }
    const closePopup = () => {
      store.commit(MutationTypes.SET_POPUP, false)
    }
    const toggleHamburger = () => {
      store.commit(MutationTypes.TOGGLE_HAMBURGER)
    }
    const closeHamburger = () => {
      store.commit(MutationTypes.CLOSE_HAMBURGER, false)
    }
    const setLocale = (lang) => {
      locale.value = lang
      localStorage.setItem('locale', lang)
    }
    const closeDropdown = (e) => {
      const target = e.target
      if (el.value && el.value !== target && !el.value.contains(target)) {
        isDropdownOpen.value = false
      }
    }
    const onProceed = () => {
      // removed only user input
      sessionStorage.removeItem('budget')
      sessionStorage.removeItem('selectedIndex')
      sessionStorage.removeItem('plug_in_hybrid')
      sessionStorage.removeItem('hybrid')
      sessionStorage.removeItem('plug_in_hybrid_drive_range')
      sessionStorage.removeItem('electric_drive_range')
      sessionStorage.removeItem('fuel_type')
      sessionStorage.removeItem('body_type')
      sessionStorage.removeItem('transmission')
      sessionStorage.removeItem('engine_power')
      sessionStorage.removeItem('cargo_space')
      sessionStorage.removeItem('towing_capacity')
      sessionStorage.removeItem('seats')
      sessionStorage.removeItem('doors')
      sessionStorage.removeItem('brands')
      router.push({ name: 'Dashboard' })
      store.commit(MutationTypes.SET_POPUP, false)
    }
    const logOut = () => {
      VueCookieNext.removeCookie('companyId')
      VueCookieNext.removeCookie('adminCompanyId')
      VueCookieNext.removeCookie('employeeCarPolicyId')
      VueCookieNext.removeCookie('roles')
      VueCookieNext.removeCookie('latestcarOrderId')
      VueCookieNext.removeCookie('status')
      sessionStorage.clear()
      localStorage.clear()
    }
    const setSuperAdminCompany = (id) => {
      VueCookieNext.setCookie('adminCompanyId', id, {
        expire: '24h',
        path: '/',
        domain: '',
        secure: '',
        sameSite: ''
      })
    }
    const removeSuperAdminCompany = () => {
      VueCookieNext.removeCookie('adminCompanyId')
    }
    onMounted(() => {
      document.addEventListener('click', closeDropdown)
    })
    onUnmounted(() => {
      document.removeEventListener('click', closeDropdown)
    })
    return {
      locale,
      showPopUp,
      onClick,
      goToDashboard,
      toggleOpen,
      toggleHamburger,
      closeHamburger,
      isDropdownOpen,
      role,
      user,
      setLocale,
      closeDropdown,
      el,
      closePopup,
      isLoggedIn,
      onProceed,
      logOut,
      companyLogo,
      loader,
      roles,
      route,
      setSuperAdminCompany,
      removeSuperAdminCompany,
      getSuperAdmin
    }
  }
})
