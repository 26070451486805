
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'Button',
  emits: ['onClick'],
  props: {
    buttonType: {
      type: String,
      default: ''
    },
    text: {
      type: String
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  }
})
