
import { defineComponent, ref, onMounted, computed } from 'vue'
import checkUserSettings from '@/mixins/checkUserSettings'
import { useStore } from '@/store'
import { ActionTypes } from '@/store/options/actions'
import formatCurrency from '@/filters/CurrencyFormat'
import getTranslatedFuelType from '@/filters/getTranslatedFuelType'
import getTranslatedBodyType from '@/filters/getTranslatedBodyType'
import { useRoute } from 'vue-router'
import ConvertToCommas from '@/filters/ConvertToCommas'
export default defineComponent({
  data() {
    return {
      series: 'BMW 1-series, 2021, 2.0 118D Auto',
      monthlyCost: '5 340,00',
      allowance: '5 000,00 (103%)',
      deducated: '140,00',
      co2: '0,5 tonnes'
    }
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const windowWidth = ref(window.innerWidth)
    const expandSidebar = ref(false)
    const collapseSidebar = ref(true)
    const carsById = computed(() => store.state.options.carsById.cars)
    const carImagePath = computed(() => store.state.options.carsById.path)
    const carOrderDetailsById = computed(() => store.state.options.carOrderDetailsById)
    const loader = computed(() => store.state.options.optionsloader)
    const NetMonthlyCostAndFlag = computed(() => store.state.options.NetMonthlyCostAndFlag)
    const leaseRateError = computed(() => store.state.options.leaseRateError)
    const { displayUserSettingPopup } = checkUserSettings()
    const imagesURl = process.env.VUE_APP_IMG_URL
    const carDetails = ref({})
    const getSidebar = async () => {
      await store.dispatch(ActionTypes.GET_CARSBYID, { vehicleId: route.params.vehicleId })
    }
    onMounted(async () => {
      window.onresize = () => {
        windowWidth.value = window.innerWidth
      }
      document.addEventListener('scroll', () => {
        const sidebar: HTMLElement = document.querySelector('.sidebar-fixed') as HTMLElement
        if (windowWidth.value > 767 && sidebar) {
          if (window.scrollY > 67) {
            sidebar.classList.add('sidebar-scroll')
          } else {
            sidebar.classList.remove('sidebar-scroll')
          }
        }
      })
      if (!displayUserSettingPopup.value) {
        await getSidebar()
      }
      // await getCarsByIdTitle()
    })
    return {
      expandSidebar,
      collapseSidebar,
      windowWidth,
      carsById,
      loader,
      imagesURl,
      carDetails,
      formatCurrency,
      getTranslatedFuelType,
      getTranslatedBodyType,
      carImagePath,
      NetMonthlyCostAndFlag,
      ConvertToCommas,
      leaseRateError,
      carOrderDetailsById
    }
  }
})
