
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'
export default defineComponent({
  setup() {
    const store = useStore()
    const roles = computed(() => store.state.auth.currentUser?.roles)
    return { roles }
  }
})
